(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/utils/assets/javascripts/has-valid-outcome-pair.js') >= 0) return;  svs.modules.push('/components/pool-betting/utils/assets/javascripts/has-valid-outcome-pair.js');
"use strict";


const hasValidOutcomePair = _ref => {
  let {
    outcomePairs,
    resultOutcome,
    maxOutcomeIndex
  } = _ref;
  if (!resultOutcome || !resultOutcome.length) {
    return false;
  }
  const resultsWithMaxValues = [];
  const homeResult = maxOutcomeIndex ? Math.min(parseInt(resultOutcome[0], 10), maxOutcomeIndex) : parseInt(resultOutcome[0], 10);
  resultsWithMaxValues.push([homeResult]);
  const awayResult = maxOutcomeIndex ? Math.min(parseInt(resultOutcome[1], 10), maxOutcomeIndex) : parseInt(resultOutcome[1], 10);
  resultsWithMaxValues.push([awayResult]);
  const resultsAsString = resultsWithMaxValues.join('-');
  return outcomePairs.includes(resultsAsString);
};
setGlobal('svs.components.poolBetting.utils.hasValidOutcomePair', hasValidOutcomePair);

 })(window);