(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/utils/assets/javascripts/format-date.js') >= 0) return;  svs.modules.push('/components/pool-betting/utils/assets/javascripts/format-date.js');
"use strict";


const {
  format,
  isToday,
  isTomorrow,
  isYesterday,
  isWithinInterval,
  addDays
} = dateFns;
const {
  sv
} = dateFns.locales;
const formatDate = (dateString, _ref) => {
  let {
    showDayOfWeek = false,
    showNearDaysAsLabel = false,
    showLongDate = false
  } = _ref;
  const date = new Date(dateString);
  const now = new Date();
  if (showNearDaysAsLabel) {
    if (isToday(date)) {
      return "idag ".concat(format(date, 'HH:mm'));
    }
    if (isTomorrow(date)) {
      return "imorgon ".concat(format(date, 'HH:mm'));
    }
    if (isYesterday(date)) {
      return "ig\xE5r ".concat(format(date, 'HH:mm'));
    }
    if (isWithinInterval(date, {
      start: addDays(now, 2),
      end: addDays(now, 6)
    })) {
      return showLongDate ? "".concat(format(date, 'EEEE HH:mm', {
        locale: sv
      })) : "".concat(format(date, 'EEE HH:mm', {
        locale: sv
      }));
    }
  }
  if (showDayOfWeek) {
    return showLongDate ? "".concat(format(date, 'EEEE d/M HH:mm', {
      locale: sv
    })) : "".concat(format(date, 'EEE d/M HH:mm', {
      locale: sv
    }));
  }
  return format(date, 'd/M HH:mm');
};
setGlobal('svs.components.poolBetting.utils.formatDate', formatDate);

 })(window);