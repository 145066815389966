(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/utils/assets/javascripts/compose-finalized-result-message.js') >= 0) return;  svs.modules.push('/components/pool-betting/utils/assets/javascripts/compose-finalized-result-message.js');
"use strict";


const {
  Currency: formatCurrencyAsString,
  CurrencyFromJupiterString: formatCurrencyAsNumber
} = svs.utils.format;
const composeFinalizedResultMessage = _ref => {
  let {
    correctCount,
    groupName,
    groupWinAmount,
    productName,
    winAmount
  } = _ref;
  const parsedWinAmount = formatCurrencyAsNumber(winAmount);
  if (parsedWinAmount > 0 && groupName && groupWinAmount) {
    if (correctCount) {
      return React.createElement(React.Fragment, null, "".concat(groupName, " fick ").concat(correctCount, " r\xE4tt p\xE5 ").concat(productName, " och har vunnit "), React.createElement("span", {
        className: "no-wrap"
      }, "".concat(formatCurrencyAsString(groupWinAmount), " kr. ")), 'Din vinst är ', React.createElement("span", {
        className: "no-wrap"
      }, "".concat(formatCurrencyAsString(winAmount), " kr. ")), "Vinsten har satts in p\xE5 ditt spelkonto.");
    }
    return React.createElement(React.Fragment, null, "".concat(groupName, " har vunnit "), React.createElement("span", {
      className: "no-wrap"
    }, "".concat(formatCurrencyAsString(groupWinAmount), " kr")), " p\xE5 ".concat(productName, "! Din vinst \xE4r "), React.createElement("span", {
      className: "no-wrap"
    }, "".concat(formatCurrencyAsString(winAmount), " kr.")), ' Vinsten har satts in på ditt spelkonto.');
  }
  if (parsedWinAmount > 0) {
    if (correctCount) {
      return React.createElement(React.Fragment, null, "Du fick ".concat(correctCount, " r\xE4tt p\xE5 ").concat(productName, " och har vunnit "), React.createElement("span", {
        className: "no-wrap"
      }, "".concat(formatCurrencyAsString(winAmount), " kr. ")), "Vinsten har satts in p\xE5 ditt spelkonto.");
    }
    return React.createElement(React.Fragment, null, 'Du har vunnit ', React.createElement("span", {
      className: "no-wrap"
    }, "".concat(formatCurrencyAsString(winAmount), " kr")), " p\xE5 ".concat(productName, "! Vinsten har satts in p\xE5 ditt spelkonto."));
  }
  if (groupName) {
    return "Tyv\xE4rr ingen vinst p\xE5 ".concat(productName, " f\xF6r ").concat(groupName, " denna g\xE5ng.");
  }
  return "Tyv\xE4rr ingen vinst p\xE5 ".concat(productName, " denna g\xE5ng.");
};
setGlobal('svs.components.poolBetting.utils', {
  composeFinalizedResultMessage
});

 })(window);