(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/utils/assets/javascripts/calc-correct-count.js') >= 0) return;  svs.modules.push('/components/pool-betting/utils/assets/javascripts/calc-correct-count.js');
"use strict";


const calcCorrectCountFromWinDivisionsRowCount = winDivisionsRowCount => {
  const firstWinIndex = winDivisionsRowCount.findIndex(rowCount => rowCount > 0);
  const highestWinDivision = winDivisionsRowCount.length - 1;
  return highestWinDivision - firstWinIndex;
};
setGlobal('svs.components.poolBetting.utils.calcCorrectCountFromWinDivisionsRowCount', calcCorrectCountFromWinDivisionsRowCount);

 })(window);